body {
    font-family: 'Quicksand', sans-serif;
}

h1, h2, h3, h4 {
    color: $secondary !important;
}

h5, h6 {
    color: $primary;
}

a, p, li {
    color: $text;
    font-size: 16px;
    line-height: 28px;
}
